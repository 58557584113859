.questionTopicList{
display: flex;
flex-wrap: wrap;
margin-top: .1rem;
.questionTopicList_title
{
    margin-right: .8rem;
}
.questionTopicList_item{
    padding: .2rem;
margin: .5rem 0 .2rem .2rem;
}
.questionTopicList_close{
    color: #F94144;
}
}
