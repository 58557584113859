.container{
    max-width: 40%;
    margin: 7rem auto;

    table {
        width: 50%;
        margin: 0 auto;
    
        thead{
            tr{
                th{
                    background-color: #1F2739;
                    padding: 2.5rem;
                    color: #fff;
                    text-align: center;
    
                    &:first-child{
                        border-top-left-radius: 25px;
                    }
    
                    &:last-child{
                        border-top-right-radius: 25px;
                    }
                }
            }
        }
    
        tbody{
            tr{
                td{
                    padding: 2.5rem;
                    &:first-child{
                        color: #FB667A;
                     }
    
                     &:not(first-child){
                        color: #A7A1AE;
                     }
                }
    
                &:last-child{
                    td:first-child{
                        border-bottom-left-radius: 25px;
                    }
                    td:last-child{
                        border-bottom-right-radius: 25px;
                    }
                }
    
                &:nth-child(odd){
                    background-color: #acec16;
                }
    
                tr:nth-child(even){
                    background-color: #62a2ec;
                }
            }
        }
    }
}





// table tbody tr:hover{
//   background-color: #464A52;
// }
// table tbody tr td:hover{
//   background-color: #FFF842;
//   color: #403E10;
//   transform: translate(6px, -6px);
//   transition: transform 0.4s ease;
//   -webkit-transition: transform 0.4s ease;
//   -moz-transition: transform 0.4s ease;
//   -ms-transition: transform 0.4s ease;
//   -o-transition: transform 0.4s ease;
//   box-shadow: -1px 1px  #7F7C21, -2px 2px  #7F7C21,-3px 3px  #7F7C21,-4px 4px 0 #7F7C21,-5px 5px 0 #7F7C21,-6px 6px 0 #7F7C21;
// }


