@import '../../variables.scss';

.statuse-condition-accept-btn
{
background-color: #0090b2;
&:hover{
    // background-color: #02657D !important;
   cursor: pointer;
   //box-shadow: 5px 5px 5px 5px #02657D ;
   }
   border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 50px;
  height: 30px;
  text-align: center;  
}