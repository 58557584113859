/* .rbt-token
{&.rbt-token-removeable
    {
display: flex;
    }
}
.dropdown-item{
    display: flex;;
    } */

    .rbt-menu
    {
        &.dropdown-menu
        {
            &.show{
        direction: ltr;
        text-align: right;
            }
        }
    }