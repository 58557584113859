@import '../../variables.scss';

.jconfirm-content>div>.save-loding{
overflow: hidden;
}
.add-question-status-checkbox {
    width: 1.1rem;
    height: 1.1rem;

    &:hover {
        cursor: pointer;
    }
}

.uplod-file {

    background-color: indigo;
    color: white;
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;  
}

.add-question-select-item {
    display: block;
    padding: 0.375rem 0.75rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.gallery {
    display: flex;
    flex-wrap: wrap;

    &.replayQuestionImage {
        margin-top: .5rem;
    }

    .btn-show-image {
        border: none;
    }
}

.gallery img {
    margin: 10px;
}

.ck-balloon-panel {
    z-index: 9999 !important
}

.error {
    border: 2px solid #F66A68 !important;
}

.addNewquestion-new-image {
    box-shadow: 0px 0px 0px 1px rgb(18, 170, 145) !important //border-color: rgb(18, 170, 145)!important;
}

// .error{
//     .ck-toolbar {
//         border-color: red !important;
//     }
//     .ck-editor__editable_inline
//     {
//         border-color: red !important;
//     }
// }
.add-question-group-item {   
    border-radius: 15px 15px 15px 15px !important;
  

}

//  .add-question-group-item-region{
//     width: 35%;
//  }
// .add-question-date{    
//     width: 35%;
//     .DatePicker{ width: 100%;}
// }
.add-question-input-item {
    .ck-toolbar {
        border-radius: 15px 15px 0px 0px !important;
    }

    .ck-editor__editable_inline {
        border-radius: 0px 0px 15px 15px !important;
        min-height: 6rem;
        padding-top: 1rem !important;
    }

}

.btn-edit-new-question {
    background-image: linear-gradient(40deg, white 40%, transparent 40%);
    background-position: 100%;
    background-size: 300%;
    color: white;
    background-color: $color1 !important;
    &:hover {
        background-color: white;       
        cursor: pointer;
        color: $color1;
        background-position: 0%;
        -webkit-box-shadow: inset 0px 1px 0px 4px $color1;
        -moz-box-shadow: inset 0px 1px 0px 4px $color1;
        box-shadow: inset 0px 1px 0px 4px $color1;
    }

    transition: all 0.7s;
}

.add-new-question-label {
    display: block;
    width: 180px;

    // text-align: center;
}

.add-question-body-color {
    background-color: $color15 !important;
}

.header-new-question-color {
    background-color: $color1 !important;
}

.add-question-corner {
    border-radius: 0 0 15px 15px !important;
}

.add-question-input-item {
    background-color: $color15;
    padding: 5px;
    width: 100% !important;
    border-radius: 15px 15px 15px 15px !important;
}

.text-editor-border-radius {
    border-radius: 15px 15px 15px 15px !important;
    line-height: 3rem;
}

.new-question-select {
    border-radius: 15px 15px 15px 15px !important;
    width: 100%;
    max-width: 22rem;
}


.add-question-checkbox {
    background-color: $color15;
    border-radius: 15px 15px 15px 15px !important;
}

.add-question-hr-line {
    flex-grow: 1;
    border-bottom: 1px solid $color16;
    width: 80%;
}

.card-footer {
    color: #fff !important;
}

.card-header {
    background-color: $color1 !important;
    color: #fff !important;
    transition: all ease-in-out 0.35s;
    &.selected-tab{
        cursor: default;
        background-color: #fff !important;
        color:#000 !important;
    }
}

.DatePicker__input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 15px 15px 15px 15px !important;
    // width: 250px !important;
}

.card-header-add-question {
    width: 200px !important;
    margin-top: 20px;
    border-radius: 1.2rem 1.2rem 0rem 0rem !important;
    border: 6px solid white;
    border-bottom: 0 !important;
    text-align: center;
    cursor: pointer;
    
}

.card-body-add-question {
    position: relative;
    // display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;

    border-radius: 1.5rem;
    box-shadow: 1px 2px 3px #ddd;
    border-top-right-radius: 0px;
}

.statuse-add-question {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: 1px 2px 0px #ddd;

}

.form-group {
    margin-bottom: .5rem !important;
}

.main-lang-label {
    min-width: 80px;
    max-width: 80px;
}

.readOnly-mainLanguage {
    pointer-events: none;

    .new-question-select {
        background-color: #F6F6F6;
    }

    .add-question-input-item {
        background-color: #F6F6F6;
    }
}

.modal-title {
    color: #fff;
}

.id-in-web_value--color-gray {
    background-color: $color17 !important;
   // color: $color2;
}
.id-in-web_value--color-green
{
    background-color: $color11 !important;
    //color: $color2;
}
.id-in-web_value--color-red
{
    background-color: $color7 !important;
    //color: #fff;
}
.id-in-web_value--color-orange
{
background-color: $color8;
}
.d-in-web_value--color-white{
    color: #fff;
}

.base-info-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.id-in-web_value--border{
    border-radius: 15px 15px 15px 15px !important;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
}
.btn-action{
    display: flex;
}
.btn-action_concel--color-red
{
   // color: $color7;
    background-color: $color7;
   
}
.btn-action_concel--border-corner
{
    border-radius: 0 0 15px 15px !important;
}
.btn-action_concel--animation {
    background-image: linear-gradient(40deg, white 40%, transparent 40%);
    background-position: 100%;
    background-size: 300%;
    color: white;

    &:hover {
        background-color: white;
        //border: 2px solid $color1;
        cursor: pointer;
        color: $color7;
        background-position: 0%;
        -webkit-box-shadow: inset 0px 1px 0px 4px $color7;
        -moz-box-shadow: inset 0px 1px 0px 4px $color7;
        box-shadow: inset 0px 1px 0px 4px $color7;
    }

    transition: all 0.7s;
}
.btn-action--size
{
    width: 50%;
}

@media (max-width: 1400px) {
    .base-info-section {
        flex-direction: column;
        align-items: start;
    }
    .add-question-group-item-region{
        width: 100%;
    }
    .add-question-date
    {
        width: 100%;
    }
    .DatePicker 
    {
        width: 100%;
    }
    .id-in-web--position{
        justify-content: end;
    }
    .id-in-web--size
    {
        width: 100%;
    }
    
}
.add-question__add-topic {    
    margin-top: 1rem;
    &:hover {     
      border: 2px solid $color1;
      color: $color1;     
    }
    background-color: $color1;
}
.questionTopic_action_button
  {   
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;    
    border: none;
    transition: all 0.7s;
    background-image: linear-gradient(40deg, white 40%, transparent 40%);
    background-position: 100%;
    background-size: 300%;
    min-height: 2.5em;
    min-width: 6.3em;
    margin-left: 0.5rem;
    &:hover {
        background-color: white;
        background-position: 0%;
        cursor: pointer;
    }
  }
  .main-language{
    min-width: 5rem;
  }
  .add-question__translate-id
  {
    max-width: 10rem;
  }