.remove{
    color: #F94144;
}
.add{
    color:#008000;
}
.question-info{p{  
    flex-basis:fit-content
}
label{ min-width:8rem}
}