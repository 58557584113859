.statuse-condition-close{
    &:hover{
        cursor: pointer;
    }
    width: 0;
    height: 0;
}
.status-condition-status-option {
   
    padding: 0.375rem 0.75rem; 
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
  }
  @media(max-width:393px){
    .status-condition-status-option {
       // width: 100px ;
    }

  }
  .status-option-connection {
    width: 60px ;
    max-width: 60px;
}
.status-option-value
{
    max-width: 90px;
}
.status-condition
{
    max-width: 190px;
}
.serchresult-icon-add{
    width: 0;
    height: 0;
}