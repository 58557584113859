@import '../../variables.scss';
.search-changetype{
    max-width: 12rem;
}
.searchtype-question{
    max-width: 30rem;
}
.search-button
{color: #fff;
    &:hover{
        color: $color1;
    }
}
