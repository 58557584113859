@import '../../variables.scss';

.showQuestionAndReplyButton {
    color: blue;

    &:hover {
        cursor: pointer;
    }
}

.disable {
    pointer-events: none;

    &.icon-split {
        &:before {
            color: #c9c9c9;
            border-style: solid;
            border-width: 1px;
            border-radius: 4px;
            border-color: #c9c9c9;
            font-size: 3rm !important;
            font-weight: bold;
        }

        &.result-row-button-icon {
            transition: all 0.2s;

            &:before {
                color: #c9c9c9 !important;
            }

            &:hover {
                cursor: pointer;
                -ms-transform: rotate(90deg);
                /* IE 9 */
                transform: rotate(90deg);
                transition: all 0.2s;

                &:before {
                    color: #fff !important;
                    background-color: #c9c9c9 !important;

                }
            }

        }
    }
}

.resultrow-replaytext {
    overflow: hidden !important;

    .ck {
        &.ck-editor__top {
            &.ck-reset_all {
                display: none;
            }
        }
    }

    .ck-editor__editable_inline {
        border: 0px solid !important;
        background-color: transparent !important;

        >:first-child {
            margin-top: 0px !important;
        }

        padding:0px !important;

        /*   white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important; */
    }


}

.resultrow-replaytext-truncate {
    .ck-editor__editable_inline {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
}

// .ck.ck-editor__editable_inline>:first-child {
//     margin-top: 0px !important;;
// }



.rotate {

    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: left top;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: left top;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: left top;
    -o-transform: rotate(90deg);
    -o-transform-origin: left top;
    transform: rotate(90deg);
    transform-origin: left top;

    position: absolute;
    top: 0;
    left: 100%;
    white-space: nowrap;
    font-size: 48px;
}

.result-row-icon {
    font-size: 1.7rem;

    .path2 {
        color: #fff;
    }
}

.result-row-icon-favorite-checked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #F3722c;
    }

    .path4:before {
        color: #F3722c !important
    }

    .path3:before {
        color: #F3722c !important
    }
}

.result-row-icon-favorite-unchecked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #c9c9c9;
    }
}

.result-row-icon-edit-checked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #F94144;
    }

    .path4:before {
        color: #F94144 !important
    }

    .path3:before {
        color: #F94144 !important
    }
}

.result-row-icon-edit-unchecked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #c9c9c9;
    }
}


.result-row-icon-link-checked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;

        &:before {
            border-color: #659c3a !important;
        }
    }

    .path3:before {
        color: #659c3a !important
    }

    .path4:before {
        color: #659c3a !important
    }
}

.result-row-icon-link-unchecked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #c9c9c9;
    }
}

.icon-split.result-row-icon-split-checked:before {
    color: $color1 !important;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: $color1 !important;
}

.result-row-icon-publish-checked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #F9C749;
    }

    .path4:before {
        color: #F9C749 !important
    }

    .path3:before {
        color: #F9C749 !important
    }
}

.result-row-icon-publish-unchecked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #c9c9c9;
    }
}

.result-row-icon-seo-checked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #6B2D5C;
    }

    .path3:before {
        color: #6B2D5C !important
    }

    .path4:before {
        color: #6B2D5C !important
    }

    .path5:before {
        color: #6B2D5C !important
    }

    .path6:before {
        color: #6B2D5C !important
    }
}

.result-row-icon-seo-unchecked {
    .path1 {
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #c9c9c9;
    }
}

.icon-edit.result-row-button-icon {

    .path1 {
        // border-style:  solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #c9c9c9;
        box-shadow: 0 0 1px 1px #c9c9c9;
    }

    .path2 {
        color: #fff;
    }

    .path4:before {
        color: $color1 !important
    }

    .path3:before {
        color: $color1 !important
    }

    &:hover {
        cursor: pointer;

        .path1 {
            //border-style:  solid;
            border-width: 1px;
            border-radius: 4px;
            border-color: $color1;
            box-shadow: 0 0 1px 1px #c9c9c9;
        }

        .path2 {
            color: $color1 !important;
        }

        .path4:before {
            color: #fff !important
        }

        .path3:before {
            color: #fff !important;
            margin-left: -1.05em;
        }
    }
}

.icon-btn-delete.result-row-button-icon {

    .path1 {
        border-width: 1px;
        border-radius: 4px;
        border-color: #c9c9c9;
        font-size: 1.9rem;
    }

    .path2::before {
        color: $color7;
        font-size: 1.9rem;
    }

    .path1:before {
        color: #fff !important;
        font-size: 1.9rem;
    }

    .path3::before {
        color: $color7;
        font-size: 1.9rem;
    }



    &:hover {
        .path1 {
            //border-style:  solid;
            border-width: 1px;
            border-radius: 4px;
            border-color: $color1;

        }

        .path2:before {
            color: #fff !important;
        }

        .path1:before {
            color: $color7 !important;
        }

        .path3:before {
            color: #fff !important;
        }

    }
}




.icon-split {
    &:before {
        color: #c9c9c9;
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        border-color: #c9c9c9;
        font-size: 3rm !important;
        font-weight: bold;
    }

    &.result-row-button-icon {
        transition: all 0.2s;

        &:before {
            color: $color1 !important;
        }

        &:hover {
            cursor: pointer;
            -ms-transform: rotate(90deg);
            /* IE 9 */
            transform: rotate(90deg);
            transition: all 0.2s;

            &:before {
                color: #fff !important;
                background-color: $color1 !important;

            }
        }

    }
}

.icon-automation-code {
    &:before {
        color: #c9c9c9 !important;       
        border-color: #c9c9c9;
        font-size: 3rm !important;
        font-weight: bold;
    }   
        &:hover {
            cursor: pointer;           
            &:before {
                color: #fff !important;
                background-color: $color1 !important;
            }
        }

    }





.result-row-button-icon {
    font-size: 2rem;
}

.result-row-check {
    width: 1.1rem;
    height: 1.1rem;

    &:hover {
        cursor: pointer;
    }
}

.div-result-row-chcek {
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    border: 1px solid #c9c9c9 !important;
    border-radius: 0.3rem;
    background-color: #fff;

    &:hover {
        cursor: pointer;
    }
}

.icon-split {
    //background-color: #fff;
}

.result-row-icon-publish-path1 {}

.question-answer {
    overflow: hidden;
    min-width: 220px !important;
}

.resultrow {
    direction: rtl;
    min-width: 934px !important;
    background-color: $color5;

    &:hover {
        background-color: $color1;
        color: #fff;
        font-style: normal;
        .icon-answer{   
            &:before{color: #fff;}
         }
         .icon-question{   
            &:before{color: #fff;}
         }
         .code{
             color: #000;
         }
    }

    &:nth-child(odd) {
        background-color: $color4;
        &:hover {
            background-color: $color1;
            color: #fff;
            font-style: bold;
            .icon-answer{   
                &:before{color: #fff;}
             }
             .icon-question{   
                &:before{color: #fff;}
             }
        }
    }   
}




// .odd {
//     background-color: $color4;
//     &:hover
//     {
//         background-color: $color6;
//     }
//     // width: 100%;
// }

.even {
    background-color: $color5;

    &:hover {
        background-color: $color6;
    }

    // width: 100%;
}

.hr-line {
    flex-grow: 1;
    border-bottom: 1px solid #F7F0F0;
    width: 70%;
}

.replytext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*flex-grow: 0;     /* do not grow   - initial value: 0 
flex-shrink: 0;   /* do not shrink - initial value: 1 
flex-basis: 25em; /* width/height  - initial value: auto **/
.code {
    flex: 0 0 4em;
}

.group {
    flex: 0 0 7em;
}

.title {
    flex: 0 0 15em;
}

.status {
    flex: 0 0 8em;
}

.action {
    flex: 0 0 10em;
}



p{
    margin: 0px !important;
}