@import '../../variables.scss';
.header
{
background-color: $color1;
padding: 0 2% !important;
&.navbar{
    a{           
       color: white !important ;
    }
    span{
        color: white !important ;
        &.dropdown-menu_items{
            &:hover{color: $color1 !important ;}
             }
    }
    .dropdown-menu{
        background-color: $color1;
        text-align: right;
        a{
            &:hover{
           color: $color1 !important ;
            }
           
        }
    }
}


}
.nav-link{
    &:hover{
        transform: scale(1.3);
    }
   transition: all .5s;
}
.nav-item{
    margin-left: 40px;
}

span.logo-sm {
    background-image: url(../../image/logo-sm-fa.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    
    right: 0;
    top: 0;
    width: 150px;
    height: 50px;   
    transition: all .5s;
    &:hover{
        transform: scale(1.3);
        cursor: pointer;
    }
}


    

