@import '../../variables.scss';
.published-status__item--size{
   width: 25px;
   height: 25px;
}

.published-status-not-match--back-color-gray
{
    background-color:$color17;    
}
.published-status-match--back-color-green
{
    background-color: $color11 !important;
    //color: $color2;
}

.id-in-web_value--color-gray {
    background-color: $color15 !important;
   // color: $color2;
}

.published-status-not-exists--back-color-red
{
    background-color: $color7 !important;
    //color: #fff;
}
.published-status-match--back-color-orange
{
background-color: $color8;
}