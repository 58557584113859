@import '../../variables.scss';
.search-option-form{
    .icon-filter{
        &:hover{
            cursor: pointer;
        }
      max-width: 2.6em;
    }
    
}
.icon-filter-gray{
    &:before{
        color: gray;
       } 
}
#siteId{
    height: 40px;
}
#question{
    height: 40px;
}



@media (min-width: 576px) {
    .searchoption-statuse-aria {
        .modal-sm {
            max-width: 400px !important;
        }
    }
    
}
@media (max-width: 992px) {
  .search-option>div{
    margin-top: 5px;
  }
    .refrenceType-item {      
        width: 243px !important;  
    }
    // .automationid{
    //     margin-top: 5px;
    // }
    // .refrenceType-item-parent{
    //     margin-top: 5px;
    // }
}
.automationid{
    min-width: 160px;
    max-width: 160px;
    height: 40px;
}
.clear-search{
    color:white;
    background-color:$color7 ;
    border: none;
    &:hover {
        background-color: white; 
        border: 2px solid $color7;
        color: $color7;  
        background-position: 0%;    
    }
    transition: all 0.7s;
    background-image: linear-gradient(40deg,white 40%,transparent 40%);
    background-position: 100%;
    background-size: 300%; 
    min-height: 2.5em;  
    min-width: 6.3em;
    margin-left: .5rem;
}

.search-button {
    background-color: $color1 !important;
    border: none;
    &:hover {
        background-color: white; 
        border: 2px solid $color1;
        .icon-search:before
        {
            color: $color1; 
        }
       
        background-position: 0%;  
        // background-color: #02657D !important;
        cursor: pointer;
    }
   
    transition: all 0.7s;
    background-image: linear-gradient(40deg,white 40%,transparent 40%);
    background-position: 100%;
    background-size: 400%;
}



.search-option-form {
    border-bottom: 10px solid #dee2e6 !important;

}

.question {
    border-top-left-radius: 0em !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.filter {
    padding-top: 1rem !important;
    padding-bottom: 2rem !important;
}

.filter-multi {
    padding-top: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1rem !important;
}

.dropdown-content .panel-content .select-panel .options {
    //direction: ltr !important; 
    padding: 0px;

    input[type=checkbox] {
        margin-left: 5px;
    }
}

.group-item {
    width: 350px !important;
    max-width: 230px !important;
}

.refrenceType-item {
    width: 230px ;    
}

.serach-toggle-button {
    margin-top: 2px;
    width: 20px;
    height: 20px;
    border: 1px solid $color1 !important;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: $color1;
    
}
