@import '../../variables.scss';

 @media all  {
    .Login {
      padding: 60px 0;
    }
  
    .loginform {
      margin: 0 auto;
      max-width: 320px;
      direction: ltr !important;
    }
  }
 
  .headercolor{
    background-color:$color6 ;
  }

  // div {
  //   input {
  //     &:checked + label {
  //       background: red;
  //     }
  //     &:checked + label + p {
  //       display: block;
  //     }
  //   }
  //   p {
  //     display:none;
  //   }
  // }
  
  .loginform
  {
   /*  direction: rtl; */
    margin-top: 10%;
    // width: 500px!important;
    // height: 600px;  
     width: 392px;
     position: fixed;
     top: 50%;
     left: 50%;
    margin-left: -195px;
     margin-top: -187px;
    // backdrop-filter: blur(15px);
    padding: 0;
    animation: Right 2s both ;
  }
  
  .bordercolor
  {
    border-color: $color6!important;
    box-shadow: 3px 3px 3px #ccc;
  }
  .col1
  {
    background-color: rebeccapurple;
  }
  .col2
  {
    background-color: rosybrown;
  }
  .col3
  {
    background-color: royalblue;
  }


  .col4
  {
    background-color: sienna;
  }
  .col5
  {
    background-color: springgreen;
  }
  .col6
  {
    background-color: tomato;
  }
  @keyframes Right{
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
      opacity: 0;
      transform: translate3d(3000px, 0, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0);
    }
    75% {
      transform: translate3d(10px, 0, 0);
    }
    90% {
      transform: translate3d(-5px, 0, 0);
    }
    to {
      transform: none;
    }
    // 0%{
    //     opacity: 0;
    //     transform:translateX(200px)
    // }
    // 100%{
    //     opacity: 1;
    //     transform:translateX(0px)
    // }
    
}

  
