//If you see an "Unknown at rule @import-normalize css(unknownAtRules)" warning in VSCode, change the css.lint.unknownAtRules setting to ignore.
//normalize.css
@import-normalize;
@import './variables.scss';

body {
  direction: rtl;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: dana, "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  /* font-size: calc(.5*(1.5vh*1.1vw)) !important;  */
  /* font-size: calc(4vw + 4vh + 2vmin); */
  min-width: 320px;
  text-align: right;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.modal-header
{
  padding: .5rem .5rem !important;
}

// @media (max-width: $small) {
//   body{ 
//    font-size: .4rem !important;
//   }
// }
// @media (min-width: $small + 1px) and (max-width: $medium) {
//   body{ 
//    font-size: .6rem !important;
//   }
// }
// @media (min-width: $medium + 1px) and (max-width: $larg) {
//   body{ 
//    font-size: .8rem !important;
//   }
//}
//این کد برای اضافه کردن سی اس اس به دکمه بستن modal اضافه شد
.btn-close{ 
  visibility: hidden;   
  &:before{
    content: 'X';
    visibility: visible;
    color: #fff;
    //font-weight: bold;
  }
}