@import '../../variables.scss';
.icon-btn-delete
{
  //box-shadow: 5px 5px 5px 5px #02657D ;
  &:hover{cursor: pointer;}
  .path1{
    &::before
    {
      color: $color7;
    }
  }
}
.result-body-header{
  height: 32px;
  margin-bottom: 5px;
}

*.icon
{
  font-size: 1.4rem;
}
.searchresult-icon-delete{
  font-size: 1.57rem;
}
.searchresult-icon-add{
  font-size: 2rem;
  &:hover{
   // background-color: #02657D !important;
  cursor: pointer;
  //box-shadow: 5px 5px 5px 5px #02657D ;
  }
}
.count-per-page {
  display: block; 
  padding: 0.375rem 0.75rem; 
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 32px;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    background-color: white;
  }
  .icon-size
  {
    width: 40px;
    height: 40px;
  }
  .result-body{
    // height:100vh ;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .search-result-count
  {
    background-color: $color1;
    //text-align: center;
    display: inline-block !important;
    min-height: 30px!important;
    align-items: center;
    justify-content:center;
    color: white;
  }
  .search-result-count-lable
  {
    //flex: 1  !important;   
  } 
  .result-page{
    max-height:95vh;
    height: 100%;
  }
//   @media (min-width: 992px)
//   {
//   .modal-lg, .modal-xl
//   {
//     max-width: 90% !important;
//   }
  
// }

.modal-lg, .modal-xl
{
  max-width: 95vw !important;
}

.header-search-result{
min-width:934px !important ;

}
.search-result-count-section{
height: 30px;
width: 180px;
box-shadow: 3px 3px 3px #ccc;
}
.pagination-container.pagination-bar
{
  min-width: 10px !important;
  padding-right: 0px !important;
  box-shadow: 3px 3px 3px #ccc;
}
@media (max-width: 576px) {
  .search-result-count-section{
    //text-align:left;
    //margin:auto;
    margin-bottom:10px;
  }
}
.styled
{
  background-color: red;
}
.code{
  &:hover
  {
    cursor: pointer;
  }
}

.help {
  position: fixed;
  
  right: 10px;
  font: 32px Arial, sans-serif;
  color: white;
  background: #bababa;
  box-shadow: 6px 3px 18px -8px rgba(0,0,0,0.75);
  border-radius: 50%;
  padding: 8px;
  width: 30px;
  height: 30px;
  
  transition: 0.5s;
}
.help:hover {
  transform: scale(1.2);
        cursor: pointer;
        color: $color1;
}
  // .search-result_comment{
  //   text-align:left;
  //   margin:auto;
  // }


