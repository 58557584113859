@import "../../variables.scss";
.topic-add {
  .topicAdd_save {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: none;
    transition: all 0.7s;
    background-image: linear-gradient(40deg, white 40%, transparent 40%);
    background-position: 100%;
    background-size: 300%;
    min-height: 2.5em;
    min-width: 6.3em;
    margin-left: 0.5rem;
    &:hover {
      background-color: white;
      background-position: 0%;
      cursor: pointer;
      border: 2px solid $color1;
      color: $color1;
    }
    background-color: $color1;
  }
  &.invalid {
    .topic-add_title {
      background-color: #ffd7d7;
      border-color: #f94144;
    }
    .topic-add_title-label {
      color: #f94144;
    }
  }
  .dropdown-item {
    display: flex;
  }
  .input{
    width: auto;
    flex-grow: 1;
  }
  .label{
    align-self: center;
    padding-left: .2rem;
    width: 5rem;
  }
  .introduction_hidden{
    display: none !important;
  }  
}
