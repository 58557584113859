.question-changed{
  overflow: scroll;
  height: 95vh;
}
.table-striped{
background-color: #F7F0F0;
tbody tr:nth-of-type(odd)
{
  background-color: #ECF9FC !important;
}
}

.table-bordered {
  &:has(tr:nth-child(1) input:checked) {
    th {
      border-bottom-width: 0px;
    }
  }

  tr {
    &:has(input:checked) {

      &:has(th) {
        border: 1px solid #dee2e6;
      }

      &:td {
        border: 2px solid black;
      }

      background-color: rgb(0, 255, 255);
      border-bottom-width: 2px;

      td {
        border-top: 2px solid black;
        border-bottom: 2px solid black;

        &:first-of-type {
          border-right: 2px solid black;
        }

        &:last-of-type {
          border-left: 2px solid black;
        }

        background-color: aqua;
      }

      th {
        border: 1px solid #dee2e6;
        border-bottom-width: 2px;
        background-color: #AAE1EE;
      }
    }
  }
}