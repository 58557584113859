@import "../../../variables.scss";
.questionTopic{
.questionTopic_add-new{
  background-color: $color1;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  min-width:auto;
  min-height: auto;
  font-size: 1.3rem;
  font-weight: 600;
  font-size: 1rem;  
  line-height: 1.5;
  &:hover
  {
    background-color: #ffff;
    color:$color1 ;
    border-color: $color1;
    border: 1px solid;
  }
}
.questionTopic_action {
  display: flex;
  justify-content: space-between;
  .questionTopic_save {    
    margin-top: 1rem;
    &:hover {     
      border: 2px solid $color1;
      color: $color1;     
    }
    background-color: $color1;
  }
  
  .questionTopic_cancel {
    margin-top: 1rem;
    &:hover {     
      border: 2px solid #F94144;;
      color: #F94144;
      
    }
    background-color: #F94144;
  }
}

}
.questionTopic_action_button
  {   
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;    
    border: none;
    transition: all 0.7s;
    background-image: linear-gradient(40deg, white 40%, transparent 40%);
    background-position: 100%;
    background-size: 300%;
    min-height: 2.5em;
    min-width: 6.3em;
    margin-left: 0.5rem;
    &:hover {
        background-color: white;
        background-position: 0%;
        cursor: pointer;
    }
  }
  .editor{
    min-width: 188px;
    width: 100%;
    min-height: 6rem; 
    .ck-editor__editable_inline { 
      min-height: 6rem;
      padding-top: 1rem !important;
    }   
  }  