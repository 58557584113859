$color1:#0090B2;
$color2:#FFFFFF;
$color3:#333132;
$color4:#F7F0F0;
$color5:#ECF9FC;
$color6:#AAE1EE;
$color7:#F94144;
$color8:#F3722C;
$color9:#717EC3;
$color10:#F9C74F;
$color11:#90BE6D;
$color12:#43AA8B;
$color13:#6B2D5C;
$color14:#00B8E3;
$color15:#F6F6F6;
$color16:#EAEAEA;
$color17:#c9c9c9;

$small: 480px;
$larg:1024px;
$medium:768px;
// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more —  Extra large screens, TV




$icomoon-font-family: "icomoon12" !default;
$icomoon-font-path: "../public/fonts" !default;


$icon-add-question-path1: "\e900";
$icon-add-question-path2: "\e901";
$icon-add-question-path3: "\e902";
$icon-answer: "\e904";
$icon-automation-code: "\e905";
$icon-btn-delete-path1: "\e909";
$icon-btn-delete-path2: "\e90a";
$icon-btn-delete-path3: "\e90b";
$icon-check-hover-path1: "\e90e";
$icon-check-hover-path2: "\e90f";
$icon-check-hover-path3: "\e910";
$icon-check-square-path1: "\e911";
$icon-check-square-path2: "\e912";
$icon-check-square-path3: "\e913";
$icon-check-square-path4: "\e914";
$icon-close: "\e915";
$icon-date: "\e916";
$icon-edit-path1: "\e924";
$icon-edit-path2: "\e925";
$icon-edit-path3: "\e926";
$icon-favorite-path1: "\e933";
$icon-favorite-path2: "\e934";
$icon-favorite-path3: "\e935";
$icon-favorite-path4: "\e936";
$icon-filter: "\e937";
$icon-group: "\e938";
$icon-link-path1: "\e945";
$icon-link-path2: "\e946";
$icon-link-path3: "\e947";
$icon-link-path4: "\e948";
$icon-logout: "\e94a";
$icon-publish-path1: "\e954";
$icon-publish-path2: "\e955";
$icon-publish-path3: "\e956";
$icon-question: "\e957";
$icon-search: "\e958";
$icon-seo-path1: "\e96b";
$icon-seo-path2: "\e96c";
$icon-seo-path3: "\e96d";
$icon-seo-path4: "\e96e";
$icon-seo-path5: "\e96f";
$icon-seo-path6: "\e970";
$icon-setting: "\e971";
$icon-site-code: "\e972";
$icon-split: "\e975";
$icon-uncheck-square-path1: "\e978";
$icon-uncheck-square-path2: "\e979";
$icon-user: "\e97a";
$icon-home2: "\e903";

