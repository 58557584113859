@import '../../variables.scss';
.separation-section
{
    border:1px solid #B8D8E0;
    box-shadow:1px 10px 1px  #c2cdcf;
    margin: 20px 40px;
    outline:8px solid #c5EFF9 ;
   // outline: 1px solid #AED2DA;
   .odd{
background-color: #8be975 !important;
   }
   .even{
    background-color: #8be975 !important;
   }
   
}

.separation-header{
    background-color: #AAE1EE;
    padding: 5px;
    border-bottom: 8px solid #c5EFF9 ;
}
.separation-button{
    background-color:$color1 ;
    color: #fff;
    &:hover{
        background-color: #02657D !important;
        cursor: pointer;
    }
    margin: 10px;   
    border: 0px !important;
}

.separation-question-input-section
{
    background-color: #ECF9FC;
    padding: 15px 0px 10px 10px ;
   // box-shadow: 5px 0px 5px 0px #888888;
    box-shadow:0px 2px 1px #c2cdcf, 0px -2px 1px #c2cdcf;
 
}

.separation-question-reaonly-section
{   
    padding: 4px 0px 4px;
    .separation-replyText-readonly
    {
        padding-left: 4px;
    }
    .ck{
    &.ck-editor__top{&.ck-reset_all
    {
        display: none;
    }}}
    .ck-editor__editable_inline
    {
        border-radius: 4px !important;
        border-color: #82C9D9 !important;
        margin-top: 4px;
        margin-bottom: 4px;   
        white-space: wrap !important;
        overflow: display !important;       
    }
}


.separation-icon
{
 padding: 8px 10px 0px 2px;
}
.separation-icon-edit{
    padding: 0px 10px 0px 2px;
}
